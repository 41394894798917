export const theme = {
  primaryColor: '#C0062F',
  secondaryColor: '#C0062F',
  lightLavnderColor: '#aa9fd4',
  mintGreenColor: '#28aa8c',
  sapphireBlueColor: '#005a96',
  whiteColor: '#ffff',
  grayColor: '#616161',
  primaryColorAlpha: (opacity = 1) => `rgba(38, 99, 75, ${opacity})`,
  secondaryColorAlpha: (opacity = 1) => `rgba(38, 99, 75, ${opacity})`,
};

import React, { Fragment, useState, useContext, useCallback, useMemo, useEffect, useRef } from 'react';
import { Alert, Button, Label, Input } from 'reactstrap';
import AppointmentsTable from '../../components/appointments/AppointmentsTable';
import { lang, isAR } from '../../lang';
import { request, APIs } from '../../request';
import { safeReq } from '../../utils/request';
import AppContext from '../../context/Context';
import { toast } from 'react-toastify';
import { APPOINTMENTS_ACTIONS } from '../../components/appointments/TableActionButton';
import ConfirmationInput from '../../components/alert/ConfirmationInput';
import useSWR from 'swr';
import { APPOINTMENT_STATUS, REVALIDATE_TIME } from '../../constants';
import { Card } from '../../components/common/Card';
import FilterDropdown from '../../components/appointments/FilterDropdown';
import { Select } from '../../components/common/CustomeSelect';
import { DatePicker } from '../../components/common/DatePicker';
import { date, prepareShiftTimeLabel, range } from '../../utils';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { prepareDate, prepareTime } from '../../utils';

const TIME = range(48).map(index => {
  const hours = `${Math.floor(index / 2)}`.padStart(2, '0');
  const min = index % 2 ? '30' : '00';
  const time = `${hours}:${min}`;
  const label = prepareShiftTimeLabel(time);
  return { label, value: time };
});

const Appointments = () => {
  const { brand, selectedStore } = useContext(AppContext);
  const [showInputModal, setShowInputModal] = useState(false);
  const [rejectedAppointmentId, setRejectedAppointmentId] = useState(0);
  const [selectedPage, setSelectedPage] = useState(1);
  const [bookings, setBookings] = useState({ data: [] });
  const [services, setServices] = useState({ data: [] });
  const [sortBy, setSortBy] = useState();
  const [sortType, setSortType] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [name, setName] = useState('');
  const [mobile, setMobile] = useState('');
  const [selectedService, setSelectedService] = useState({});
  const [selectedStatus, setSelectedStatus] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const [perPage, setPerPage] = useState(10);
  const tableRef = useRef(null);
  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'appointments',
    sheet: 'appointments',
  });

  const timeFromOptions = useMemo(() => {
    const opts = [...TIME];
    opts.pop();
    return opts;
  }, []);

  const timeToOptions = useMemo(() => {
    const opts = [...TIME];
    opts.shift();
    return opts;
  }, []);

  const fetchData = useCallback(
    async (
      _,
      store_id,
      page,
      sortBy,
      sortType,
      name,
      mobile,
      serviceId,
      status,
      startTime,
      endTime,
      startDate,
      endDate,
      perPage
    ) => {
      const from = startDate && date.format(startDate, 'yyyy-MM-dd');
      const to = endDate && date.format(endDate, 'yyyy-MM-dd');
      const { data } = await request.GET(APIs.APPOINTMENTS, {
        brandId: brand.id,
        store_id,
        page,
        sortBy,
        sortType,
        service_id: serviceId,
        status,
        name,
        mobile,
        fromTime: startTime,
        toTime: endTime,
        from,
        to,
        per_page: perPage,
      });
      return data;
    },
    [brand.id]
  );

  const fetchServices = useCallback(async (_, store_id) => {
    const { data } = await request.GET(APIs.STORE_SERVICES, {
      store_id,
      trashed: true,
    });
    return data;
  }, []);

  const { data, mutate } = useSWR(
    [
      APIs.APPOINTMENTS,
      selectedStore.id,
      selectedPage,
      sortBy,
      sortType,
      name,
      mobile,
      selectedService.id,
      selectedStatus.value,
      startTime,
      endTime,
      startDate,
      endDate,
      perPage,
    ],
    fetchData,
    {
      refreshInterval: REVALIDATE_TIME,
    }
  );

  const { data: servicesData } = useSWR([APIs.STORE_SERVICES, selectedStore.id], fetchServices, {
    refreshInterval: REVALIDATE_TIME,
  });

  useEffect(() => {
    data && setBookings(data);
    servicesData && setServices(servicesData);
  }, [data, servicesData]);

  const loading = !data || !servicesData;

  const onSortChange = useCallback((sortBy, sortType) => {
    setSortBy(sortBy);
    setSortType(sortType);
  }, []);

  const onActionClick = useCallback(
    async (id, actionType) => {
      const body = { appointmentId: id };
      switch (actionType) {
        case APPOINTMENTS_ACTIONS.ACCEPT:
          await request.PUT(APIs.ACCEPT_APPOINTMENT, body);
          toast(lang.appointments.appointmentAccepted);
          break;
        case APPOINTMENTS_ACTIONS.REJECT:
          onRejectClick(id);
          break;
        case APPOINTMENTS_ACTIONS.CANCEL:
          await request.DELETE(APIs.CANCEL_APPOINTMENTS, body);
          toast(lang.appointments.appointmentCanceled);
          break;
        default:
          console.log('Should not reach here');
      }
      mutate();
    },
    [mutate]
  );

  const onPaginationClick = useCallback(page => {
    setSelectedPage(page);
  }, []);

  const onRejectClick = id => {
    setRejectedAppointmentId(id);
    setShowInputModal(true);
  };

  const rejectedAppointment = useCallback(
    input => {
      safeReq(async () => {
        setShowInputModal(false);
        await request.PUT(APIs.REJECT_APPOINTMENT, { appointmentId: rejectedAppointmentId, comment: input });
        mutate();
        toast.success(lang.appointments.appointmentRejected);
      });
    },
    [rejectedAppointmentId, mutate]
  );

  const onFilterButtonClicked = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  const onNameChange = useCallback(e => {
    setSelectedPage(1);
    setName(e.target.value);
  }, []);

  const onMobileChange = useCallback(e => {
    setSelectedPage(1);
    setMobile(e.target.value);
  }, []);

  const servicesFilterOptions = useMemo(
    () =>
      services.data.map(service => ({
        label: service[isAR ? 'name_ar' : 'name_en'],
        ...service,
      })),
    [services]
  );

  const onServiceSelected = useCallback(service => {
    setSelectedPage(1);
    setSelectedService(service);
  }, []);

  const statuses = useMemo(
    () => Object.values(APPOINTMENT_STATUS).map(el => ({ label: lang.appointments[el], value: el })),
    []
  );

  const onDateChange = useCallback(dates => {
    const [start, end] = dates;
    setSelectedPage(1);
    setStartDate(start);
    setEndDate(end);
  }, []);

  const onStartTimeChange = useCallback(value => {
    setSelectedPage(1);
    setStartTime(value);
  }, []);

  const onEndTimeChange = useCallback(value => {
    setSelectedPage(1);
    setEndTime(value);
  }, []);

  const onStatusChange = value => {
    setSelectedPage(1);
    setSelectedStatus(value);
  };

  const onClearAllClicked = useCallback(() => {
    setName('');
    setMobile('');
    setSelectedStatus({});
    setSelectedService({});
    setStartDate(null);
    setEndDate(null);
    setStartTime(null);
    setEndTime(null);
  }, []);

  const checkStatus = status => {
    switch (status) {
      case APPOINTMENT_STATUS.ARRIVED:
        return lang.appointments.arrived;
      case APPOINTMENT_STATUS.WALK_IN:
        return lang.appointments.walk_in;
      case APPOINTMENT_STATUS.ACCEPTED:
        return lang.appointments.accepted;
      case APPOINTMENT_STATUS.REJECTED:
        return lang.appointments.rejected;
      case APPOINTMENT_STATUS.CONFIRMED:
        return lang.appointments.confirmed;
      case APPOINTMENT_STATUS.CANCELED:
        return lang.appointments.canceled;
      case APPOINTMENT_STATUS.NOT_ARRIVED:
        return lang.appointments.not_arrived;
      case APPOINTMENT_STATUS.EXPIRED:
        return lang.appointments.expired;
      case APPOINTMENT_STATUS.PENDING:
        return lang.appointments.pending;
    }
  };

  const onShowMoreClick = () => {
    setPerPage(1000);
    setSelectedPage(1);
  };

  return (
    <Fragment>
      <table ref={tableRef} style={{ display: 'none' }}>
        <thead>
          <tr>
            <th>{lang.appointments.attendeeName}</th>
            <th>{lang.appointments.service_name}</th>
            <th>{lang.appointments.mobile}</th>
            <th>{lang.appointments.date}</th>
            <th>{lang.appointments.time}</th>
            <th>{lang.appointments.status}</th>
          </tr>
        </thead>
        <tbody>
          {bookings.data?.map(book => (
            <tr key={book.id}>
              <td>{book?.user?.name || lang.appointments.guest}</td>
              <td>{book.service ? book.service[isAR ? 'name_ar' : 'name_en'] : ''}</td>
              <td>{book?.user?.mobile}</td>
              <td>{prepareDate(book?.start_datetime)}</td>
              <td>{prepareTime(book?.start_datetime)}</td>
              <td>{checkStatus(book?.status)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Button color="primary" outline className="appts-btn mb-3" onClick={onFilterButtonClicked}>
        {lang.label.filter}
      </Button>
      <Button color="primary" outline className="appts-btn mb-3" onClick={onDownload}>
        {lang.label.download}
      </Button>
      <Button color="primary" outline className="appts-btn mb-3" onClick={onShowMoreClick}>
        {lang.viewAll}
      </Button>

      <Card className={`appts-filters-container pt-3 pb-4 mb-4 ${showFilters ? '' : 'hide'}`}>
        <div className="d-flex justify-content-around mb-2">
          <div className="filter-container">
            <Label>{lang.appointments.attendeeName}</Label>
            <Input value={name} onChange={onNameChange} />
          </div>
          <div className="filter-container">
            <Label>{lang.appointments.mobile}</Label>
            <Input value={mobile} onChange={onMobileChange} />
          </div>
          <div className="filter-container">
            <Label>{lang.appointments.date}</Label>
            <DatePicker
              selectsRange
              selected={startDate}
              onChange={onDateChange}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
        </div>
        <div className="d-flex justify-content-around">
          <div className="filter-container">
            <Label>{lang.appointments.time}</Label>
            <div className="d-flex align-items-center">
              <label className="text-muted mr-2">
                <small className="text-capitalize">{lang.from}</small>
              </label>
              <span className="f-1">
                <Select options={timeFromOptions} onChange={onStartTimeChange} value={startTime} />
              </span>
              <label className="text-muted mx-2">
                <small className="text-capitalize">{lang.to}</small>
              </label>
              <span className="f-1">
                <Select options={timeToOptions} onChange={onEndTimeChange} value={endTime} />
              </span>
            </div>
          </div>
          <div className="filter-container">
            <Label>{lang.appointments.service_name}</Label>
            <FilterDropdown
              selected={selectedService[isAR ? 'name_ar' : 'name_en']}
              options={servicesFilterOptions}
              onChange={onServiceSelected}
            />
          </div>
          <div className="filter-container">
            <Label>{lang.appointments.status}</Label>
            <FilterDropdown selected={selectedStatus.label} options={statuses} onChange={onStatusChange} />
          </div>
        </div>
        <button className="btn btn-link clear-filters-btn" onClick={onClearAllClicked}>
          {lang.btn.clear}
        </button>
      </Card>
      {loading || bookings.data.length ? (
        <AppointmentsTable
          bookings={bookings}
          onActionClick={onActionClick}
          onPaginationClick={onPaginationClick}
          onSortChange={onSortChange}
          loading={loading}
        />
      ) : (
        <Alert color="info" className="mb-0">
          {lang.appointments.no_appointments}
        </Alert>
      )}
      <ConfirmationInput
        open={showInputModal}
        confirm={rejectedAppointment}
        cancel={() => setShowInputModal(false)}
        title={lang.appointments.appointmentRejection}
        body={lang.appointments.resonOfRejectionInputlabel}
      />
    </Fragment>
  );
};

export default Appointments;
